/* Node component styles */
:root {
    --node-bgd: rgb(51, 46, 46);
    --node-bgd2: #272424;
    /*--node-border: #494545;*/
    --node-border: #463f3f;
    --active-color: #fce941;
    --warning-color: #ff0202;
    --solution-color: blue;
    --button-text: #2d2828; /*#f9f9f9*/
    --removal-text: white;
}

.node {
    width: 200px;
    height: 100px;
    /*background-color: #f9f9f9; !* Light gray background *!*/
    background-color: var(--node-bgd); /* Light gray background */
    /*border: 1px solid #ccc;*/
    border: 1px solid var(--node-border);
    border-radius: 10px; /* Rounded corners */
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative; /* Needed for badge positioning */
    transition: transform 0.3s, background-color 0.3s;
    cursor: pointer;
    display: inline-block;
    margin: 20px;
}

.node:hover {
    transform: scale(1.05); /* Scale by 5% */
}

.node {
    border-color: var(--node-border);
}


.node.active {box-shadow: var(--active-color) 0 0 6px 1px; border-color: var(--active-color);}
.node.active.risk {box-shadow: var(--warning-color) 0 0 6px 1px; border-color: var(--warning-color)}
.node.active.solution {box-shadow: var(--solution-color) 0 0 6px 1px; border-color: var(--solution-color)}

.node.solution {
    border-color: var(--solution-color);
}
.node.risk {
    border-color: var(--warning-color);
}

.node.end {
    border-radius: 45px 0 45px 0;
}


.node.solution .node-type {
    color: white;
}

.node.risk .node-type {
    color: var(--warning-color);
}


.getting-started-button {
    padding: 10px 20px;
    background-color: var(--active-color);
    color: var(--button-text);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
}

/* Badge in top-right corner, centered */
.badge {
    width: 50px;
    height: 50px;
    background-color: #3498db;
    color: white;
    border-radius: 50%;
    position: absolute;
    top: -25px; /* Half the height of the badge */
    right: -25px; /* Half the width of the badge */
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
    cursor: pointer;
}

/* Money badge */
.money-badge {
    width: 80px; /* Width of the money badge */
    height: 50px; /* Same height as the time badge */
    background-color: white; /* White background */
    color: gray; /* Gray text */
    border-radius: 25px; /* Rounded corners */
    position: absolute;
    top: -25px; /* Align with the badge */
    right: -110px; /* Position it to the left of the time badge */
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
    border: none; /* Removed borders */
}

/* Popup for setting time cost */
.popup {
    /*position: absolute;*/
    top: 100px;
    left: 50%;
    width: 300px; /* Increased width */

    /*background-color: white;*/
    /*border: 1px solid #ccc;*/

    z-index: 10;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);

    /*transform: none; !* Removed translation effect *!*/
    /*!* Prevent scaling effect *!*/
    /*transform: scale(1); !* Ensure the popup does not scale *!*/

    /* Other popup styles */
    padding: 20px;
    background-color: var(--node-bgd2);
    color: white;
    border: none;
}

.popup textarea, .popup input {
    background-color: #332e2e;
    color: white;
}

.popup .unit-buttons {
    display: flex;
    /*justify-content: space-between;*/
    justify-content: center;
    margin-bottom: 15px; /* Space between unit buttons and amount input */
}

.popup .unit-buttons button {
    padding: 5px 10px;
    /*border: 1px solid #ccc;*/
    border: 1px solid #4e4a4a;
    cursor: pointer;

    background-color: var(--node-bgd2);
}

.popup .unit-buttons button.active {
    background-color: var(--active-color);
    color: var(--button-text);
}

.unit-buttons button {
    border: 1px solid #ccc;

    color: white;
}

.unit-buttons button.left {
    border-radius: 13px 0 0 13px;
}

.unit-buttons button.right {
    border-radius: 0 13px 13px 0;
}



.popup label {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.popup label input {
    margin-left: 10px;
}

.popup-buttons {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
}

.popup button {
    padding: 5px 10px;
}

/* Styling for the link section within the popup */
.link-section {
    margin-top: 10px;
    display: none;
}

.node-title {
    padding: 0;
    margin: 0;
    max-height: 75px;
    overflow: hidden;

    /*text-overflow: ellipsis;*/
    /*width: 160px;*/
    /*height: 3.2em;*/
    /*white-space: nowrap;*/
    color: var(--active-color);
}

.note {
    min-width: 100%;
    min-height: 150px;
}

.link-section h5 {
    margin: 10px 0 5px;
}

.link-section input[type="text"],
.link-section input[type="url"] {
    width: 100%;
    margin: 5px 0;
    padding: 5px;
}

.link-section button {
    margin: 5px 0;
    padding: 5px 10px;
}

.link-section ul {
    list-style-type: none;
    padding: 0;
}

.link-section li {
    margin: 5px 0;
}

.link-section a {
    color: #3498db;
    text-decoration: underline;
}

/* Adjustments for the popup layout */
.popup label {
    display: block;
    margin-top: 10px;
}

.popup-panel {
    padding: 10px;
    border-radius: 5px; /* Optional: adds rounded corners for a softer look */
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); /* Optional: adds a slight shadow for depth */
    background-color: var(--button-text)
}

.panel-separator {
    height: 20px; /* Space between panels */
}

.section-title {
    font-size: 1.2em; /* Adjust size as needed */
    font-weight: bold; /* Makes the title bold */
    color: #333; /* Change color as per your design */
    margin-bottom: 10px; /* Space below the title */
}

.costs-wrapper {
    color: gray;
}

.costs-hours {

}

.costs-money {
    /*color: mediumspringgreen;*/
    /*color: gray;*/
}

.node-costs {
    position: absolute;
    bottom: -30px;
    left: 0;
    right: 0;
    color: gray;
}

.node-type {
    position: absolute;
    bottom: 6px;
    left: 0; right: 0;
    /*color: black;*/
    color: whitesmoke;
    font-size: 85%;
}

.node-title-component {
    position: absolute;
    top: 15px;
    left: 0;
    right: 0;
    z-index: 100;
}

.node-child-spawn {
    position: absolute;
    bottom: -80px;
    left: 0;
    right: 0;
    color: gray
}

.hold-to-remove-container {
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;
    width: 100%;
    height: 100%;
}

.progress-radial {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.progress-overlay {
    pointer-events: none;
    z-index: 0;
}

.progress-content {
    z-index: 1;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 72px;
    color: var(--removal-text);
}
